.property-list-section{padding-top: 100px;padding-bottom: 100px;}

.item-container {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 2em;
  }
  .card {
    text-align: center;
    padding: 10px;
    width: 300px;
    border-radius: 5px;
    padding: 1em;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  }
  img {
    height: 100px;
    width: auto;
  }